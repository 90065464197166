/* eslint-disable */
import React from "react";
import { useEvent } from '../hooks/Hooks';
import LandingPage from '../components/LandingPage/LandingPage';
import OpenGridContainer from "../components/OpenGridContainer/OpenGridContainer";

const IndexPage = () => {

  const [openGridHasLoaded, setOpenGridHasLoaded] = React.useState(false);
  const [displayOpenGrid, setDisplayOpenGrid] = React.useState(false);
  const [build, setBuild] = React.useState(null);
  const [teleportId, setTeleportId] = React.useState(null);
  const triggerEvents = [{ type: 'loaded_ui_manager' }, { type: 'return_to_website' }];
  const knowsSecretPassword = localStorage.getItem('opengrid::knows_password');
  const [showSecretPasswordModal, setShowSecretPasswordModal] = React.useState(!knowsSecretPassword);

  React.useEffect(() => {
    if (openGridHasLoaded) {
      build.SendMessage("GameController", "SetMobileMode", "1");
      if (teleportId) setTimeout(() => teleport(teleportId), 100);
    }
  }, [openGridHasLoaded]);

  triggerEvents.forEach(event => useEvent(event.type, (e) => {
    if (e.type === 'return_to_website' && displayOpenGrid) setDisplayOpenGrid(false);
    if (e.type === 'loaded_ui_manager') setOpenGridHasLoaded(true);
  }));

  const handleOpenGridEntrance = (id) => {
    if (openGridHasLoaded) teleport(id)
    else setTeleportId(id);
    setDisplayOpenGrid(true);
  }

  const teleport = (id) => build.SendMessage("Player", "Teleport", id);

  return (<>
      <LandingPage
        showSecretPasswordModal={showSecretPasswordModal}
        setShowSecretPasswordModal={setShowSecretPasswordModal}
        displayOpenGrid={displayOpenGrid}
        handleOpenGridEntrance={handleOpenGridEntrance}
    />
    {showSecretPasswordModal === false &&
        <OpenGridContainer
        displayOpenGrid={displayOpenGrid}
        setBuild={setBuild}/>
     } 
        
  </>);
}

export default IndexPage;